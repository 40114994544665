import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Link } from "react-router-dom";
import './contact.css';

const Contact = () => {
    const form = useRef();
    const [formData, setFormData] = useState({
      to_company: '',
      to_name: '',
      contact_no: '',
      reply_to: '',
    });
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
  
    const validateForm = () => {
      const newErrors = {};
      if (!formData.to_company.trim()) newErrors.to_company = 'Company Name is required.';
      if (!formData.to_name.trim()) newErrors.to_name = 'Name is required.';
      if (!formData.contact_no.trim() || !/^\d+$/.test(formData.contact_no)) newErrors.contact_no = 'Valid Contact No is required.';
      if (!formData.reply_to.trim() || !/\S+@\S+\.\S+/.test(formData.reply_to)) newErrors.reply_to = 'Valid Email ID is required.';
      return newErrors;
    };
  
    const handleInputChange = (e) => {
      setFormData({ ...formData, [e.target.name]: e.target.value });
      setErrors({ ...errors, [e.target.name]: '' }); // Clear error when typing
    };
  
    const sendEmail = (e) => {
      e.preventDefault();
      const validationErrors = validateForm();
      if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        return;
      }
  
      setIsLoading(true); // Start the loader
      emailjs
        .sendForm('service_gmui4y7', 'template_rb2xfnk', form.current, 'HbYIDQc3TvxMmG9cV')
        .then(
          () => {
            setSuccessMessage('Your message has been sent successfully!');
            setFormData({ to_company: '', to_name: '', contact_no: '', reply_to: '' }); // Reset form
            setErrors({});
            form.current.reset();
            setIsLoading(false); // Stop the loader
          },
          (error) => {
            console.log('FAILED...', error.text);
            setSuccessMessage('Failed to send the message. Please try again.');
            setIsLoading(false); // Stop the loader
          }
        );
    };
    return(
        <div className="">
            <div className="inner-banner">
                <div className='inner-content contact-inner'>
                    <h1>Contact Us</h1>
                    <p>Have questions or need support? Reach out to the WFTracker team—we’re here to help!</p>
                </div>
            </div>
            <div className="contact-page">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                            <div class="row mb-3">
                                <div class="col-lg-2 col-md-2 col-sm-2 col-2">
                                    <i class="fa-solid fa-envelope"></i>
                                </div>
                                <div class="col-lg-10 col-md-10 col-sm-10 col-10">
                                    <span class="">Email ID</span>
                                    <p class="">hello@infisuite.com</p>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-2 col-md-2 col-sm-2 col-2">
                                    <i class="fa-solid fa-phone"></i>
                                </div>
                                <div class="col-lg-10 col-md-10 col-sm-10 col-10">
                                    <span class="">Call Today</span>
                                    <p class="">+91 817 994 7020</p>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-lg-2 col-md-2 col-sm-2 col-2">
                                    <i class="fa-regular fa-clock"></i>
                                </div>
                                <div class="col-lg-10 col-md-10 col-sm-10 col-10">
                                    <span class="">Monday - Friday</span>
                                    <p class="">10AM - 7PM</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-2 col-md-2 col-sm-2 col-2">
                                    <i class="fa-solid fa-location-dot"></i>
                                </div>
                                <div class="col-lg-10 col-md-10 col-sm-10 col-10">
                                    <span class="">Office</span>
                                    <p class="">812, Block 5, MH2 BUILDING, HYD, 500009</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-8 col-md-8 col-sm-12 col-12'>
                            <form ref={form} onSubmit={sendEmail}>
                            <div className='con-bg'>
                                <h3>Get In Touch</h3>
                                <div class="row form-margin">
                                    <div class="col-lg-6 mb-4">
                                    <div className="form-group">
                                            <label className="form-label text-white">Name</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="to_name"
                                                placeholder="Name"
                                                value={formData.to_name}
                                                onChange={handleInputChange}
                                            />
                                            {errors.to_name && <small style={{ color: 'red' }}>{errors.to_name}</small>}
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mb-4">
                                        <div class="form-group">
                                            <label class="form-label text-white">Designation</label>
                                            <input class="form-control" placeholder="Designation" type="text" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mb-4">
                                    <div class="form-group">
                                            <label class="form-label text-white">Company Name</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="to_company"
                                                placeholder="Company Name"
                                                value={formData.to_company}
                                                onChange={handleInputChange}
                                            />
                                            {errors.to_company && <small style={{ color: 'red' }}>{errors.to_company}</small>}                                        
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mb-4">
                                    <div className="form-group">
                                            <label className="form-label text-white">Contact No</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="contact_no"
                                                placeholder="Contact No"
                                                value={formData.contact_no}
                                                onChange={handleInputChange}
                                            />
                                            {errors.contact_no && <small style={{ color: 'red' }}>{errors.contact_no}</small>}
                                        </div>
                                    </div>
                                    <div class="col-lg-6 mb-4">
                                    <div className="form-group">
                                            <label className="form-label text-white">Email ID</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="reply_to"
                                                placeholder="Email ID"
                                                value={formData.reply_to}
                                                onChange={handleInputChange}
                                            />
                                            {errors.reply_to && <small style={{ color: 'red' }}>{errors.reply_to}</small>}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                    <button className="btn custom_btn" type="submit" disabled={isLoading}>
                                        {isLoading ? (
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        ) : (
                                            'Submit'
                                        )}
                                     </button>
                                        {successMessage && <p style={{ color: 'white', marginTop: '10px' }}>{successMessage}</p>}
                                    </div>
                                </div>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* <div className='mt-5 mb-5'>
                    <img className="w-100" src="./GoogleMap.jpg" alt='Map' />
                </div> */}
            </div>
        </div>
    );
}

export default Contact;