import { Link } from "react-router-dom";
import './price.css';

const Price = () =>{
    return(
        <div className="">
            <div className="inner-banner">
                <div className='inner-content contact-inner'>
                    <h1>Price</h1>
                    <p>We offer flexible pricing plans to suit businesses of all sizes and budgets. Find the perfect solution to meet your specific needs and start experiencing the benefits of our powerful platform today.</p>
                </div>
            </div>
            <div className='price'>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                            <div className="price-left">
                                <h3>Our Team Is Ready To Assist You</h3>
                                <h4>Request Your Quote Today</h4>
                                <p>Get started today! Request a free quote and take the first step towards a more efficient and profitable future for your field service business.</p>
                                <Link to="/contact" className="btn custom_btn">Contact Us</Link>
                            </div>
                        </div>
                        <div className="col-lg-12 price-tab">
                            <ul className="nav nav-pills mb-4" id="pills-tab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="monthly" data-bs-toggle="pill" data-bs-target="#monthly_sec" type="button" role="tab" aria-controls="monthly_sec" aria-selected="true">Monthly 
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="annually" data-bs-toggle="pill" data-bs-target="#annually_sec" type="button" role="tab" aria-controls="annually" aria-selected="false">Annually
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                <div className="tab-pane fade show active" id="monthly_sec" role="tabpanel" aria-labelledby="monthly">
                                    <div className="table-responsive">
                                        <table className="table custom_table">
                                            <tr>
                                                <th width="40%" className="text-center">
                                                    <img src="../arrow.svg" alt="Arrow" />
                                                    <b className="text-primary d-block">Save 3 months cost</b>
                                                </th>
                                                <th width="20%" className="text-center pt-4">
                                                    <h4>Basic</h4>
                                                    <h5><i class="fa-solid fa-indian-rupee-sign"></i> 149</h5>
                                                    <p>/user/month</p>
                                                </th>
                                                <th width="20%" className="text-center position-relative pt-4">
                                                    <badge className="badge"><i class="fa-solid fa-star"></i> Popular</badge>
                                                    <h4>Standard</h4>
                                                    <h5><i class="fa-solid fa-indian-rupee-sign"></i> 249</h5>
                                                    <p>/user/month</p>
                                                </th>
                                                <th width="20%" className="text-center pt-4">
                                                    <h4>Enterprise</h4>
                                                    <h5><i class="fa-solid fa-indian-rupee-sign"></i> 449</h5>
                                                    <p>/user/month</p>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td colSpan="4"><h6>License</h6></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Number of Users</span>
                                                </td>
                                                <td align="center">
                                                    <span>10+ Users</span>
                                                </td>
                                                <td align="center">
                                                    <span>10+ Users</span>
                                                </td>
                                                <td align="center">
                                                    <span>10+ Users</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4"><h6>Attendence</h6></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Attendance - In & Out</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Multiple Checkin & Checkout</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Multiple Attendance Session</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Auto Logout</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Checkin Approval</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Edit Meeting Notes</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4"><h6>Geo-fence</h6></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Multiple Locations</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Location based Attendance Geo-restriction</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Geo-restriction for Checkout</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4"><h6>Authentication</h6></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Individual Web Login</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Prevent Multi-device Login</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Fingerprint Authentication</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4"><h6>Tracking</h6></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Live Tracking</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(240 hours/user/month)</strong>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(Unlimited)</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Route Travelled</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Sort Tracking Page</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>GPS On & Off Notification</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Calculate GPS Off Time</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Calculate Internet Off Time</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Play and Pause Route</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Capture Battery Percentage on Every Activity</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <h6>Offline</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Meeting Notes - Image & File Upload</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Multiple Checkin & Checkout</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Live Tracking</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Fake Location Notification</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <h6>Organization Structure</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Team Reporting Structure</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <h6>Reminders and Notifications</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Attendance Reminder</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Waiting Time Notification</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Checkout Reminder</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Working Hours Notification</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <h6>Forms</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>General Forms</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Customize Sub-forms</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Product Quantity Available</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Restrict Customer List to Users</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Verify Customer Visits via OTP Authentication</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <span>Add-on</span>
                                                </td>
                                                <td align="center">
                                                    <span>Add-on</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Business Card Reader</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Transport Type Field</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Route Plan Form Field</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Image Upload</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(Max 20 Fields)</strong>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(20+ Fields)</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">File Upload</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(Max 20 Fields)</strong>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(20+ Fields)</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Video Upload</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <h6>Roles and Permissions</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Roles and Permissions Module</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Customized Reports</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Live Report</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Daily Report - Web View</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Download Reports</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">API</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Email Meeting MoM in Realtime</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <h6>Departments, Customer and Products</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Create Departments</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Customer List Management</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Product Management with Category</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Product Management with Groups</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(Max 20 Groups)</strong>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(20+ Groups)</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <h6>Easy Dial</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Dial your Reporting Manager & Users</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Dial your Leads</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <h6>Route Plan</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Route Plan</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Approve & Reject Route Plan</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <h6>Hierarchy</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Hierarchy Level</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Map Users, Leads, Customers & Products to Hierarchy</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Pin Assigned Hierarchy Location (In Mobile)</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <h6>Reimbursement</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Reimbursement Request & Approval</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            
                                            <tr>
                                                <td colspan="4">
                                                    <h6>Storage</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Data Retention</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(1 Year)</strong>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(2 Year)</strong>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(5 Year)</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <h6>Support</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Email</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Chat</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Phone</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Dedicated Account Manager</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(100+ Licenses)</strong>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(50+ Licenses)</strong>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(30+ Licenses)</strong>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 text-end">
                                            <button className="btn custom_btn">Sign Up</button>
                                            <button className="btn custom_btn">Sign Up</button>
                                            <button className="btn custom_btn">Sign Up</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade show" id="annually_sec" role="tabpanel" aria-labelledby="annually">
                                    <div className="table-responsive">
                                        <table className="table custom_table">
                                            <tr>
                                                <th width="40%" className="text-center">
                                                    <img src="../arrow.svg" alt="Arrow" />
                                                    <b className="text-primary d-block">Save 3 months cost</b>
                                                </th>
                                                <th width="20%" className="text-center pt-4">
                                                    <h4>Basic</h4>
                                                    <h5><i class="fa-solid fa-indian-rupee-sign"></i> 149</h5>
                                                    <p>/user/month</p>
                                                </th>
                                                <th width="20%" className="text-center position-relative pt-4">
                                                    <badge className="badge"><i class="fa-solid fa-star"></i> Popular</badge>
                                                    <h4>Standard</h4>
                                                    <h5><i class="fa-solid fa-indian-rupee-sign"></i> 249</h5>
                                                    <p>/user/month</p>
                                                </th>
                                                <th width="20%" className="text-center pt-4">
                                                    <h4>Enterprise</h4>
                                                    <h5><i class="fa-solid fa-indian-rupee-sign"></i> 449</h5>
                                                    <p>/user/month</p>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td colSpan="4"><h6>License</h6></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Number of Users</span>
                                                </td>
                                                <td align="center">
                                                    <span>1+ Users</span>
                                                </td>
                                                <td align="center">
                                                    <span>1+ Users</span>
                                                </td>
                                                <td align="center">
                                                    <span>1+ Users</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4"><h6>Attendence</h6></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Attendance - In & Out</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Multiple Checkin & Checkout</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Multiple Attendance Session</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Auto Logout</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Checkin Approval</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Edit Meeting Notes</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4"><h6>Geo-fence</h6></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Multiple Locations</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Location based Attendance Geo-restriction</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Geo-restriction for Checkout</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4"><h6>Authentication</h6></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Individual Web Login</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Prevent Multi-device Login</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Fingerprint Authentication</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan="4"><h6>Tracking</h6></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Live Tracking</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(240 hours/user/month)</strong>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(Unlimited)</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Route Travelled</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Sort Tracking Page</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>GPS On & Off Notification</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Calculate GPS Off Time</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Calculate Internet Off Time</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Play and Pause Route</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Capture Battery Percentage on Every Activity</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <h6>Offline</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Meeting Notes - Image & File Upload</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Multiple Checkin & Checkout</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Live Tracking</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Fake Location Notification</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <h6>Organization Structure</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Team Reporting Structure</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <h6>Reminders and Notifications</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Attendance Reminder</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Waiting Time Notification</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Checkout Reminder</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Working Hours Notification</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <h6>Forms</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>General Forms</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Customize Sub-forms</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Product Quantity Available</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Restrict Customer List to Users</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Verify Customer Visits via OTP Authentication</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <span>Add-on</span>
                                                </td>
                                                <td align="center">
                                                    <span>Add-on</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Business Card Reader</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Transport Type Field</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Route Plan Form Field</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span>Image Upload</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(Max 20 Fields)</strong>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(20+ Fields)</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">File Upload</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(Max 20 Fields)</strong>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(20+ Fields)</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Video Upload</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <h6>Roles and Permissions</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Roles and Permissions Module</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Customized Reports</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Live Report</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Daily Report - Web View</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Download Reports</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">API</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Email Meeting MoM in Realtime</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                                <td align="center">
                                                    <span title="Name">Add-on</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <h6>Departments, Customer and Products</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Create Departments</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Customer List Management</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Product Management with Category</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Product Management with Groups</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(Max 20 Groups)</strong>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(20+ Groups)</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <h6>Easy Dial</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Dial your Reporting Manager & Users</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Dial your Leads</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <h6>Route Plan</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Route Plan</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Approve & Reject Route Plan</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <h6>Hierarchy</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Hierarchy Level</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Map Users, Leads, Customers & Products to Hierarchy</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Pin Assigned Hierarchy Location (In Mobile)</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <h6>Reimbursement</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Reimbursement Request & Approval</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-xmark"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            
                                            <tr>
                                                <td colspan="4">
                                                    <h6>Storage</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Data Retention</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(1 Year)</strong>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(2 Year)</strong>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(5 Year)</strong>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="4">
                                                    <h6>Support</h6>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Email</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Chat</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Phone</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <span title="Name">Dedicated Account Manager</span>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(100+ Licenses)</strong>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(50+ Licenses)</strong>
                                                </td>
                                                <td align="center">
                                                    <i class="fa-solid fa-check"></i>
                                                    <strong>(30+ Licenses)</strong>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 text-end">
                                            <button className="btn custom_btn">Sign Up</button>
                                            <button className="btn custom_btn">Sign Up</button>
                                            <button className="btn custom_btn">Sign Up</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Price;