import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Link } from "react-router-dom";
import './popup.css';

const Popup = () =>{
    const form = useRef();
    const [minDate, setMinDate] = useState("");
    // Set the minimum date to today
  React.useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    setMinDate(today);
  }, []);
    const [formData, setFormData] = useState({
        to_company: '',
        to_name: '',
        contact_no: '',
        reply_to: '',
    });
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const validateForm = () => {
        const newErrors = {};
        if (!formData.to_company.trim()) newErrors.to_company = 'Company Name is required.';
        if (!formData.to_name.trim()) newErrors.to_name = 'Name is required.';
        if (!formData.contact_no.trim() || !/^\d+$/.test(formData.contact_no)) newErrors.contact_no = 'Valid Contact No is required.';
        if (!formData.reply_to.trim() || !/\S+@\S+\.\S+/.test(formData.reply_to)) newErrors.reply_to = 'Valid Email ID is required.';
        return newErrors;
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: '' }); // Clear error when typing
    };

    const sendEmail = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setIsLoading(true); // Start the loader
        emailjs
            .sendForm('service_gmui4y7', 'template_gvx0pxh', form.current, 'HbYIDQc3TvxMmG9cV')
            .then(
                () => {
                    setSuccessMessage('Your message has been sent successfully!');
                    setFormData({ to_company: '', to_name: '', contact_no: '', reply_to: '' }); // Reset form
                    setErrors({});
                    form.current.reset();
                    setIsLoading(false); // Stop the loader
                },
                (error) => {
                    console.log('FAILED...', error.text);
                    setSuccessMessage('Failed to send the message. Please try again.');
                    setIsLoading(false); // Stop the loader
                }
            );
    };
    return(
        <div className="">
            <div className="modal" id="myModal">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-body popup-content">
                            <button type="button" className="btn-close" data-bs-dismiss="modal"></button>
                            <div className="row">
                                <div className="col-lg-6">
                                    <img className='logo-blue' src="./popup-logo.png" alt='Logo' />
                                    <div className='popup-margin'>
                                        <span>Book a Demo </span>
                                        <h3>What Can you except from this demo?</h3>
                                        <ul>
                                            <li><i class="fa-solid fa-check"></i>
                                                <b>Gain High Level Understanding</b>
                                            </li>
                                            <li><i class="fa-solid fa-check"></i>
                                                <b>Get to know some unique features</b>
                                            </li>
                                            <li><i class="fa-solid fa-check"></i>
                                                <b>Share your current challanges</b>
                                            </li>
                                        </ul>
                                        <h4>Transform Your Process Today!!</h4>
                                    </div>
                                </div>
                                <div className='col-lg-6 mt-4 mb-4'>
                                   <form ref={form} onSubmit={sendEmail}>
                                    <div className='pop-mrgn'>
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                            <label className="form-label">Name</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="to_name"
                                                    placeholder="Name"
                                                    value={formData.to_name}
                                                    onChange={handleInputChange}
                                                />
                                                {errors.to_name && <small style={{ color: 'red' }}>{errors.to_name}</small>}
                                            </div>
                                            <div className='col-lg-6'>
                                            <div class="form-group">
                                                <label class="form-label">Company Name</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="to_company"
                                                    placeholder="Company Name"
                                                    value={formData.to_company}
                                                    onChange={handleInputChange}
                                                />
                                                {errors.to_company && <small style={{ color: 'red' }}>{errors.to_company}</small>}
                                            </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <div className="form-group">
                                                    <label className="form-label">Email ID</label>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="reply_to"
                                                        placeholder="Email ID"
                                                        value={formData.reply_to}
                                                        onChange={handleInputChange}
                                                    />
                                                    {errors.reply_to && <small style={{ color: 'red' }}>{errors.reply_to}</small>}
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                            <div className="form-group">
                                                <label className="form-label">Contact No</label>
                                                <input
                                                    className="form-control"
                                                    type="text"
                                                    name="contact_no"
                                                    placeholder="Contact No"
                                                    value={formData.contact_no}
                                                    onChange={handleInputChange}
                                                />
                                                {errors.contact_no && <small style={{ color: 'red' }}>{errors.contact_no}</small>}
                                            </div>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <label className="form-label">Country</label>
                                            <select className='form-control' name="country">
                                                <option>India</option>
                                                <option>AUS</option>
                                                <option>UK</option>
                                                <option>US</option>
                                            </select>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <div className='form-group'>
                                                    <label className="form-label">Convenient Date</label>
                                                    <input type="date" className='form-control' name="conv_date" min={minDate} />
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className='form-group'>
                                                    <label className="form-label">Convenient Time</label>
                                                    <input type="time" className='form-control' name="conv_time" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <label className="form-label">Write Your Question</label>
                                            <textarea className='form-control' name="message"></textarea>
                                        </div>

                                        <button className="btn custom_btn_2" type="submit" disabled={isLoading}>
                                                {isLoading ? (
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                ) : (
                                                    'Submit'
                                                )}
                                            </button>
                                            {successMessage && <p style={{ color: 'green', marginTop: '10px' }}>{successMessage}</p>}                                    </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Popup;