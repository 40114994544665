import './feature.css';

const Feature = () => {
    return(
        <div className="">
            <div className="inner-banner">
                <div className='inner-content contact-inner'>
                    <h1>Features</h1>
                    <p>Discover the innovative features that set us apart. Our platform is designed to simplify your field service operations, improve customer satisfaction, and drive significant business growth.</p>
                </div>
            </div>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-lg-3 col-md-3 col-sm-3 col-6'>
                        <img className='w-100 mrgn-top' src="./f-1.jpg" alt='Mission' />
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-3 col-6'>
                    <img className='w-100 mrgn-top-2' src="./f-2.jpg" alt='Mission' />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                        <div className='feature-sec'>
                            <h2>Our Features</h2>
                            <p>Experience the power of our comprehensive feature set. Streamline your field operations, enhance efficiency, and gain valuable insights with our intuitive and user-friendly platform</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className="row m-0 mt-5 mb-5">
                    <div className='col-lg-4 col-md-4 col-sm-6 col-6 f-box f-box-1'>
                        <div className=''>
                            <div class="circle">
                                <i class="fa-solid fa-signal"></i>
                                <div class="circle_1"></div>
                                <div class="circle_2"></div>
                            </div>
                            <h4>Real-Time Location Tracking</h4>
                            <span>Monitor your field staff's exact location in real-time with GPS integration. Ensure transparency, enhance safety, and optimize resource allocation by knowing where your team is at all times.</span>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-6 f-box f-box-2'>
                        <div className=''>
                            <div class="circle">
                                <i class="fa-solid fa-message"></i>
                                <div class="circle_1"></div>
                                <div class="circle_2"></div>
                            </div>
                            <h4>Task Assignment and Status Updates</h4>
                            <span>Assign tasks effortlessly and receive instant updates on their status. Track progress, prioritize tasks, and ensure deadlines are met without manual follow-ups.</span>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-6 f-box f-box-3'>
                        <div className=''>
                            <div class="circle">
                                <i className="fa-regular fa-clock"></i>
                                <div className="circle_1"></div>
                                <div className="circle_2"></div>
                            </div>
                            <h4>Attendance and Time Logging</h4>
                            <span>Simplify attendance management with on-the-go check-ins and check-outs. Keep track of work hours, breaks, and overtime with automated time logging for accurate payroll and compliance.</span>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-6 f-box f-box-3'>
                        <div className=''>
                            <div class="circle">
                                <i className="fa-solid fa-photo-film"></i>
                                <div className="circle_1"></div>
                                <div className="circle_2"></div>
                            </div>
                            <h4>Route Optimization</h4>
                            <span>Empower your staff with optimized routes to save time and fuel. Reduce travel costs and improve efficiency by minimizing unnecessary detours and delays.</span>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-6 f-box f-box-1'>
                        <div className=''>
                            <div className="circle">
                                <i className="fa-brands fa-rocketchat"></i>
                                <div className="circle_1"></div>
                                <div className="circle_2"></div>
                            </div>
                            <h4>Customizable Reports and Analytics</h4>
                            <span>Access detailed insights with customizable reports on staff performance, task completion, and travel metrics. Make data-driven decisions to improve productivity and efficiency.</span>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-6 f-box f-box-2'>
                        <div className=''>
                            <div class="circle">
                                <i className="fa-regular fa-address-book"></i>
                                <div className="circle_1"></div>
                                <div className="circle_2"></div>
                            </div>
                            <h4>Offline Mode with Auto-Sync</h4>
                            <span>Ensure uninterrupted operations even in low-connectivity areas. Work offline, and the system will automatically sync data when the connection is restored.
                            </span>
                        </div>
                    </div>
                    {/* <div className='col-lg-4 col-md-4 col-sm-6 col-6 f-box f-box-2'>
                        <div className=''>
                            <div class="circle">
                                <i class="fa-regular fa-compass"></i>
                                <div class="circle_1"></div>
                                <div class="circle_2"></div>
                            </div>
                            <h4>Location Tracking</h4>
                            <span>Monitor shared locations and track the device's location through WhatsApp.</span>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-4 col-sm-6 col-6 f-box f-box-1'>
                        <div className=''>
                            <div class="circle">
                                <i class="fa-regular fa-bell"></i>
                                <div class="circle_1"></div>
                                <div class="circle_2"></div>
                            </div>
                            <h4>Notifications</h4>
                            <span>Receive alerts for specific activities, such as when a contact comes online.</span>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}

export default Feature;