
import { Link } from "react-router-dom";
import "./home.css";
import Popup from "./popup";


const Home = () => {
    return(
        <div className="full-bg">
            <div className="bg position-relative overflow-hidden">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-7 banner-title">
                            <h1>Get <b>Mobile App</b> for Field Staff</h1> 
                        </div>
                        <div className="col-lg-5 banner-title">
                            <p>For On line Reporting and Activity Tracking</p>  
                            <button type="button" data-bs-toggle="modal" data-bs-target="#myModal" className="btn custom_btn_2">Get It Now</button> 
                            <Popup />
                        </div>
                    </div>
                    <div className="anim_line">
                        <span><img src="anim_line.png" alt="anim_line" /></span>
                        <span><img src="anim_line.png" alt="anim_line" /></span>
                        <span><img src="anim_line.png" alt="anim_line" /></span>
                        <span><img src="anim_line.png" alt="anim_line" /></span>
                        <span><img src="anim_line.png" alt="anim_line" /></span>
                        <span><img src="anim_line.png" alt="anim_line" /></span>
                        <span><img src="anim_line.png" alt="anim_line" /></span>
                        <span><img src="anim_line.png" alt="anim_line" /></span>
                        <span><img src="anim_line.png" alt="anim_line" /></span>
                    </div>
                </div>
            </div>
            <div className="blue-bg">
                <div className="container-fluid">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <img className="w-100 application" src="gps-track.webp" />
                        </div>
                        <div className="col-lg-5 col-md-12 col-sm-12 col-12 title-3">
                            <p>GPS Track & Report App</p>
                            <h3>Application For GPS Location Tracking of employees & Field Staff Online Reporting</h3>
                            <Link to="/contact" type="button" class="btn getit_btn">
                                <i class="fa-solid fa-plus"></i> Get It Now
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="feature-bg">
                <div className="container mt-5">
                    <div className="title-2">
                        <span>Have A Look At</span>
                        <h2>Usefulness / Features / benefits Of Field Staff Mobile App</h2>   
                    </div>
                    <div className="feature mt-5">
                        <ul className="nav nav-pills mb-4" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">
                                    <div className="title-sec">
                                        <h3>Mobile App Useful for Co.</h3> 
                                    </div>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
                                    <div className="title-sec">
                                        <h3>Features of Mobile app</h3> 
                                    </div>
                                </button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">
                                    <div className="title-sec">
                                        <h3>Benefit to Company by App</h3> 
                                    </div>
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <div className="row align-items-center">
                                    <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <img className="w-100" src="benefit.png" alt="Banner" />
                                    </div>
                                    <div className="col-lg-6 col-md-7 col-sm-7 col-12 offset-1">
                                        <h3>Mobile App Useful for Co.</h3> 
                                        <ul>
                                            <li><i class="fa-solid fa-check"></i>
                                                <span><b>Company having marketing staff in field</b> Like Agro, Pharma, FMCG, Manufactures, Wholsalers, Finance, Insurance, All</span>
                                            </li>
                                            <li><i class="fa-solid fa-check"></i>
                                                <span><b>Company having service staff in field</b> Like repairing of Washing Machine, Lift ext., R.O., Air condition, Computers, Appliance</span>
                                            </li>
                                            <li><i class="fa-solid fa-check"></i>
                                                <span><b>Company having site staff at site</b> JCB Work, Govt. contract Work of Dam, Bridges, Construction Site, Road work ext.</span>
                                            </li>
                                            <li><i class="fa-solid fa-check"></i>
                                                <span><b>Other Staff,</b> How visit in the field for payment collection, Purchase, Demo, Installations, Delivery, Drivers</span>
                                            </li>
                                            <li><i class="fa-solid fa-check"></i>
                                                <span><b>For Manager/Owners,</b> Our Mobile App is also useful For GPS tracking of employees.</span>
                                            </li>
                                        </ul>
                                        <img className="img-100" src="100.png" alt="100%" />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                                <div className="row align-items-center">
                                    <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <img className="w-100" src="benefit.png" alt="Banner" />
                                    </div>
                                    <div className="col-lg-6 col-md-7 col-sm-7 col-12 offset-1">
                                        <h3>Features of Mobile app</h3> 
                                        <ul>
                                            <li><i class="fa-solid fa-check"></i>
                                                <span><b>Online Staff's</b> Current Location & Route of Employees on Map is available</span>
                                            </li>
                                            <li><i class="fa-solid fa-check"></i>
                                                <span><b>staff</b> Tour / Visit Entry in mobile</span>
                                            </li>
                                            <li><i class="fa-solid fa-check"></i>
                                                <span>Online Employee<b>Expense </b>Entry</span>
                                            </li>
                                            <li><i class="fa-solid fa-check"></i>
                                                <span>Online <b>Collection </b>of cash/cheque entry</span>
                                            </li>
                                            <li><i class="fa-solid fa-check"></i>
                                                <span>Employee<b>Leave Sanction</b> system</span>
                                            </li>
                                            <li><i class="fa-solid fa-check"></i>
                                                <span>Mobile base<b>Sales / Order</b> Tracking</span>
                                            </li>
                                            <li><i class="fa-solid fa-check"></i>
                                                <span>Field Workforce<b>Live Attendance</b></span>
                                            </li>
                                            <li><i class="fa-solid fa-check"></i>
                                                <span><b>Complain Entry,</b> View by staff in mobile</span>
                                            </li>
                                            <li><i class="fa-solid fa-check"></i>
                                                <span><b>Customer's</b> detail view in mobile</span>
                                            </li>
                                            <li><i class="fa-solid fa-check"></i>
                                                <span><b>Online Reports</b> of work done</span>
                                            </li>
                                            <li><i class="fa-solid fa-check"></i>
                                                <span><b>Live Online</b> all kind of reporting</span>
                                            </li>
                                        </ul>
                                        <img className="img-100" src="100.png" alt="100%" />
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">
                                <div className="row align-items-center">
                                    <div className="col-lg-5 col-md-5 col-sm-5 col-12">
                                        <img className="w-100" src="benefit.png" alt="Banner" />
                                    </div>
                                    <div className="col-lg-6 col-md-5 col-sm-7 col-12 offset-1">
                                        <h3>Benefit to Company by App</h3> 
                                        <ul>
                                            <li><i class="fa-solid fa-check"></i>
                                                <span><b>Staff GPS Location</b> Tracking / Route Travel</span>
                                            </li>
                                            <li><i class="fa-solid fa-check"></i>
                                                <span><b>Increase</b> profit / decreasing false expenses</span>
                                            </li>
                                            <li><i class="fa-solid fa-check"></i>
                                                <span><b>Employee </b>Performance Improvement</span>
                                            </li>
                                            <li><i class="fa-solid fa-check"></i>
                                                <span><b>Reduce </b>Administrative Costs</span>
                                            </li>
                                            <li><i class="fa-solid fa-check"></i>
                                                <span>Live Online<b>Staff / Employee</b> Management</span>
                                            </li>
                                            <li><i class="fa-solid fa-check"></i>
                                                <span>Send <b>Tour Plan</b> to Employee</span>
                                            </li>
                                            <li><i class="fa-solid fa-check"></i>
                                                <span>Company <b>News, Events and Internal Message</b>on staff mobile</span>
                                            </li>
                                            <li><i class="fa-solid fa-check"></i>
                                                <span>Prospective Clients<b>Prospective Clients</b></span>
                                            </li>
                                            <li><i class="fa-solid fa-check"></i>
                                                <span>Employee<b>Attendance & Time</b> Tracking</span>
                                            </li>
                                            <li><i class="fa-solid fa-check"></i>
                                                <span>Live reporting of<b>Field Workforce</b> involved in Marketing/Service</span>
                                            </li>
                                        </ul>
                                        <img className="img-100" src="100.png" alt="100%" />
                                    </div>
                                </div>
                            </div>
                        </div>   
                    </div>
                </div>
            </div>

            <div className="overflow-hidden">
                {/* <div class="title-2">
                    <span>What we will provide</span>
                    <h2>Field Staff GPS Tracking And Employee Reporting Mobile Application</h2>
                </div> */}
                <div className="row">
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 box box_1">
                        <div className="">
                            <span class="number-sec">1</span>
                            <h3>Mobile Apps for your field Staff</h3>
                            <p>Empower your field workforce with mobile apps designed to streamline operations, improve productivity, and enhance communication. These apps enable real-time data collection, task management, GPS tracking, and seamless reporting.</p>
                            {/* <Link to="/">Read more</Link> */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 box box_2">
                        <div className="">
                            <span class="number-sec">2</span>
                            <h3>Admin website for Office Staff</h3>
                            <p>Simplify office operations with a powerful admin website designed for centralized management and control. This platform enables office staff to monitor field activities, assign tasks, track progress, generate reports, and manage resources efficiently.</p>
                            {/* <Link to="/">Read more</Link> */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 box box_4">
                        <div className="">
                            <span class="number-sec">3</span>
                            <h3>App For Manager / Owners</h3>
                            <p>Stay in control with a dedicated app for managers and owners that provides real-time insights into business operations. Monitor performance metrics, track team activities, review reports, and make informed decisions—all from your mobile device.</p>
                            {/* <Link to="/">Read more</Link> */}
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6 col-12 box box_3">
                        <div className="">
                            <span class="number-sec">4</span>
                            <h3>Real-Time GPS <br />Tracking</h3>
                            <p>Track the live location of your field staff to ensure visibility, accountability, and efficient route planning. Improve productivity, enhance safety, and streamline operations with accurate, real-time location monitoring.</p>
                            {/* <Link to="/">Read more</Link> */}
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="reason"></div>

            <div className="reason-sec">
                <div className="container">
                    <span>The Reason Why Choose Us</span>
                    <h3>Field Staff Management offers a comprehensive solution to streamline field operations and boost productivity. </h3>
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <strong>Streamline your field operations with our intuitive platform. Effortlessly schedule, track, and communicate with your field staff, optimize routes, and gain valuable insights to improve efficiency and customer satisfaction</strong>
                            <Link to="/contact">Get It Now</Link>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-5 mob-center">
                            <div className="circle">
                                <i class="fa-solid fa-location-dot"></i>
                                <div className="circle_1"></div>
                                <div className="circle_2"></div>
                            </div>
                            <h5>Location Tracking</h5>
                            <p>Field Staff Route Travel Map Is Available, By Our GPS Location Tracking.</p>
                        </div>
                        <div className="col-lg-3 col-md-6 col-sm-6 col-12 mt-5 mob-center">
                            <div className="circle">
                                <i class="fa-regular fa-calendar-days"></i>
                                <div className="circle_1"></div>
                                <div className="circle_2"></div>
                            </div>
                            <h5>Attendence</h5>
                            <p>Attendence and Time Tracking App With Daily Allowance And km Travel.</p>
                        </div>
                    </div>
                    <div className="row align-items-center">
                        <div className="col-lg-3 col-md-12 col-sm-12 col-12">
                            
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-4 col-12 mt-5 mob-center">
                            <div className="circle">
                                <i class="fa-solid fa-cart-plus"></i>
                                <div className="circle_1"></div>
                                <div className="circle_2"></div>
                            </div>
                            <h5>Order</h5>
                            <p>Order Entry With Party, DateTime, Product, Price, Quantity.</p>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-4 col-12 mt-5 mob-center">
                            <div className="circle">
                                <i class="fa-solid fa-calculator"></i>
                                <div className="circle_1"></div>
                                <div className="circle_2"></div>
                            </div>
                            <h5>Expense</h5>
                            <p>Expense Detail With Time, Location, Amount, Detail and Photo.</p>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-4 col-12 mt-5 mob-center">
                            <div className="circle">
                                <i class="fa-solid fa-arrows-turn-right"></i>
                                <div className="circle_1"></div>
                                <div className="circle_2"></div>
                            </div>
                            <h5>Visit</h5>
                            <p>Visit Tracking With Time, Location, Work Detail, Next Followup, Photo.</p>
                        </div>
                    </div>
                </div>
            </div>                

            <div className="call-to-action mt-5">
                <div className="container call-to">
                    <div className="row align-items-center">
                        <div className="col-lg-7 col-md-10 col-sm-12 col-12">
                            <span>Let's Build Your Application!</span>
                            <h3>
                            "Stay on top of your tasks and ensure seamless operations—submit your daily reports, update job progress, and log work hours with just a few clicks.</h3>
                            <Link to="/contact" className="btn custom_btn">Contact Us</Link>
                        </div>
                        <div className="col-lg-5 col-md-2 col-sm-12 col-12 text-end">
                            
                        </div>
                    </div>           
                </div>
            </div>        

            <div className="bg-white overall-benefit">
                <div className="container">
                    <div className="col-lg-12 text-center">
                        <h3 className="">Overall Benefit</h3>
                    </div>
                    <ul className="mt-5">
                        <li className="benefit_box benefit_box_1">
                            <div class="">
                                <h4>Reduce</h4>
                                <p><i class="fa-solid fa-arrow-down"></i>
                                    <span>Reduce 90% manual work of Field Staff</span></p>
                                <p><i class="fa-solid fa-arrow-down"></i>
                                    <span>Reduce 50% - 60% work of Office Staff</span></p>
                                <p><i class="fa-solid fa-arrow-down"></i>
                                    <span>Reduce 20% False Tours, Visits &amp; Expenses</span>
                                </p>
                            </div>
                        </li>
                        <li className="benefit_box benefit_box_2">
                            <div class="">
                                <h4>Increase</h4>
                                <p><i class="fa-solid fa-arrow-up"></i>
                                    <span>Increase Profit & Performance by 20%</span></p>
                                <p><i class="fa-solid fa-arrow-up"></i>
                                    <span>Increase Employee / Staff Performance by 20%</span></p>
                                <p><i class="fa-solid fa-arrow-up"></i>
                                    <span>100% Peace of Mind</span>
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            
            
            {/* <div className="bg-white pt-5 pb-5">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                            <div className="other-box other-box-1">
                                <i class="fa-solid fa-screwdriver-wrench"></i>
                                <h5>Staff Mobile App Features</h5>
                                <p>Features of field staff reporting and location tracking mobile app.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                            <div className="other-box other-box-2">
                                <i class="fa-solid fa-mobile-screen"></i>
                                <h5>Screens of Mobile App</h5>
                                <p>Screenshots of field staff tracking and reporting mobile app.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-4 col-12">
                            <div className="other-box other-box-3">
                                <i class="fa-brands fa-searchengin"></i>
                                <h5>FAQ about Mobile App</h5>
                                <p>Which Mobile App required for field staff?</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default Home;