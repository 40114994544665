import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import "./header.css";



const Header = () => {

  const [isFixed, setIsFixed] = useState(false);
 
  const handleScroll = () => {
    if (window.scrollY >= 50) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };
 
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll); // Cleanup on unmount
    };
  }, []);

  
 


    return(
      <div className={`header ${isFixed ? 'fixed' : ''}`}>
        <nav className="navbar navbar-expand-lg custom-menu">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-4 col-sm-4 col-8">
                <Link className="navbar-brand logo" to="/">
                  <img src="logo.png" alt="logo" />
                </Link>
              </div>
              <div className="col-lg-9 col-md-8 col-sm-8 col-4">
                <button className="navbar-toggler float-end" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse text-end" id="navbarSupportedContent">
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0 w-100 text-end">
                    <li className="nav-item">
                      <NavLink className="nav-link" aria-current="page" to="/">Home</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/about">About Us</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/feature">Features</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/price">Price</NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/contact">Contact Us</NavLink>
                    </li>
                  </ul>
                  <Link to="/contact" className="btn custom_btn">Register</Link>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <nav className="navbar bg-body-tertiary fixed-top mobile-header">
          <div className="container-fluid">
            <a className="navbar-brand logo" href="#">
              <img src="logo.png" alt="logo" />
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="offcanvas offcanvas-end" tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
              <div className="offcanvas-header">
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
              </div>
              <div className="offcanvas-body">
                <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                  <li className="nav-item">
                    <Link className="nav-link active" aria-current="page" to="/">Home</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/about">About Us</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/feature">Features</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/price">Price</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
}

export default Header;