import { Link } from "react-router-dom";
import './about.css';

const About = () => {
    return(
        <div className=''>
            <div className="inner-banner">
                <div className='inner-content'>
                    <h1>Welcome To WFTracker</h1>
                    <p>We empower businesses to optimize their field operations. Our innovative platform connects businesses with skilled professionals, streamlining workflows and driving exceptional results.</p>
                </div>
            </div>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-lg-3 col-md-3 col-sm-3 col-6'>
                        <img className='w-100 mrgn-top' src="./6.jpg" alt='Mission' />
                    </div>
                    <div className='col-lg-3 col-md-3 col-sm-3 col-6'>
                    <img className='w-100 mrgn-top-2' src="./7.jpg" alt='Mission' />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-6 col-12'>
                        <div className='hdng'>
                            <h2>Our Story</h2>
                            <p>We understand the challenges of managing a distributed workforce. Frustrated by the limitations of existing solutions, we built a platform that simplifies scheduling, streamlines communication, and provides valuable insights to improve efficiency and customer satisfaction</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="reason-sec about-page mb-5 pb-5">
                <div className="container">
                    <span>The Reason Why Choose Us</span>
                    <h3>Field Staff Management offers a comprehensive solution to streamline field operations and boost productivity. </h3>
                    <div className="row">
                        <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                            <strong>In today's fast-paced world, efficient field operations are critical for business success. WFTracker provides a powerful platform that empowers businesses to streamline their field services, enhance customer satisfaction, and gain a competitive edge. Discover why choosing us is the right decision for your organization</strong>
                            <Link to="/contact">Get It Now</Link>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-5 mob-center p-0">
                            <div className="about-box-1">
                                <div className="circle">
                                    <i class="fa-solid fa-bullseye"></i>
                                    <div className="circle_1"></div>
                                    <div className="circle_2"></div>
                                </div>
                                <h5>Unmatched Efficiency</h5>
                                <p>Streamline operations, reduce costs, and boost productivity with our intuitive platform.</p>
                            </div>
                            <div className="about-box-3">
                                <div className="circle">
                                    <i class="fa-solid fa-calculator"></i>
                                    <div className="circle_1"></div>
                                    <div className="circle_2"></div>
                                </div>
                                <h5>Enhanced Visibility & Control</h5>
                                <p>Gain real-time insights into field staff activities, track progress, and make data-driven decisions.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt-5 mob-center p-0 mrgn-mi">
                            <div className="about-box-2">
                                <div className="circle">
                                    <i class="fa-solid fa-eye"></i>
                                    <div className="circle_1"></div>
                                    <div className="circle_2"></div>
                                </div>
                                <h5>Improved Customer Satisfaction</h5>
                                <p>Deliver exceptional service with faster response times, increased communication, and optimized scheduling.</p>
                            </div>
                            <div className="about-box-4">
                                <div className="circle">
                                    <i class="fa-solid fa-cart-plus"></i>
                                    <div className="circle_1"></div>
                                    <div className="circle_2"></div>
                                </div>
                                <h5>Unparalleled Support</h5>
                                <p>Benefit from our dedicated support team and comprehensive resources to ensure your success.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
        </div>
    );
}

export default About;