
import React from "react";
import Header from "./components/header";
import Footer from "./components/footer";
import ScrollToTop from "./components/ScrollToTop"; // Import the ScrollToTop component
import Home from "./pages/home";
import About from "./pages/about";
import Contact from "./pages/contact";
import Feature from "./pages/feature";
import Price from "./pages/price";

import{
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';

function App() {
  return (
    <div>
        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='about' element={<About />} /> 
          <Route path='/feature' element={<Feature />} /> 
          <Route path='/price' element={<Price />} /> 
          <Route path='/contact' element={<Contact />} /> 
        </Routes>
        <Header />
        <Footer />
        
    </div>
  );
}

export default App;
