import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { Link } from "react-router-dom";
import "./footer.css";


const Footer = () => {

    const form = useRef();
    const [formData, setFormData] = useState({
        to_company: '',
        to_name: '',
        contact_no: '',
        reply_to: '',
    });
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const validateForm = () => {
        const newErrors = {};
        if (!formData.to_company.trim()) newErrors.to_company = 'Company Name is required.';
        if (!formData.to_name.trim()) newErrors.to_name = 'Name is required.';
        if (!formData.contact_no.trim() || !/^\d+$/.test(formData.contact_no)) newErrors.contact_no = 'Valid Contact No is required.';
        if (!formData.reply_to.trim() || !/\S+@\S+\.\S+/.test(formData.reply_to)) newErrors.reply_to = 'Valid Email ID is required.';
        return newErrors;
    };

    const handleInputChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: '' }); // Clear error when typing
    };

    const sendEmail = (e) => {
        e.preventDefault();
        const validationErrors = validateForm();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }

        setIsLoading(true); // Start the loader
        emailjs
            .sendForm('service_gmui4y7', 'template_rb2xfnk', form.current, 'HbYIDQc3TvxMmG9cV')
            .then(
                () => {
                    setSuccessMessage('Your message has been sent successfully!');
                    setFormData({ to_company: '', to_name: '', contact_no: '', reply_to: '' }); // Reset form
                    setErrors({});
                    form.current.reset();
                    setIsLoading(false); // Stop the loader
                },
                (error) => {
                    console.log('FAILED...', error.text);
                    setSuccessMessage('Failed to send the message. Please try again.');
                    setIsLoading(false); // Stop the loader
                }
            );
    };
    return (
        <div className="">
            <div className="get_it">
                <div className="container">
                    <div className="row get-it-sec">
                        <div className="col-lg-4 col-md-5 col-sm-6 col-12">
                            <div class="title-2 text-start">
                                {/* <span>Contact Us</span> */}
                                <h3 class="">Get More Done</h3>
                            </div>
                            <div className="row align-items-center mb-3">
                                <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                                    <i class="fa-solid fa-envelope text-white"></i>
                                </div>
                                <div className="col-lg-10 col-md-11 col-sm-10 col-11">
                                    <span className="text-white">Email ID</span>
                                    <p className="text-white m-0">hello@infisuite.com</p>
                                </div>
                            </div>
                            <div className="row align-items-center mb-3">
                                <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                                    <i class="fa-solid fa-phone text-white"></i>
                                </div>
                                <div className="col-lg-10 col-md-11 col-sm-10 col-11">
                                    <span className="text-white">Call Today</span>
                                    <p className="text-white m-0">+91 817 994 7020</p>
                                </div>
                            </div>
                            <div className="row align-items-center mb-3">
                                <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                                    <i class="fa-regular fa-clock text-white"></i>
                                </div>
                                <div className="col-lg-10 col-md-11 col-sm-10 col-11">
                                    <span className="text-white">Monday - Friday</span>
                                    <p className="text-white m-0">10AM - 7PM</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-1 col-md-1 col-sm-1 col-1">
                                    <i class="fa-solid fa-location-dot text-white"></i>
                                </div>
                                <div className="col-lg-10 col-md-11 col-sm-10 col-11">
                                    <span className="text-white">Office</span>
                                    <p className="text-white m-0">812, Block 5, MH2 BUILDING, HYD, 500009</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2 col-md-3 col-sm-6 col-12 quick-link">
                            <h3>Quick Link</h3>
                            <ul>
                                <li><Link to="/"><i class="fa-solid fa-angles-right"></i>Home</Link></li>
                                <li><Link to="/about"><i class="fa-solid fa-angles-right"></i>About Us</Link></li>
                                <li><Link to="/feature"><i class="fa-solid fa-angles-right"></i>Features</Link></li>
                                <li><Link to="/price"><i class="fa-solid fa-angles-right"></i>Price</Link></li>
                                <li><Link to="/contact"><i class="fa-solid fa-angles-right"></i>Contact Us</Link></li>
                            </ul>
                        </div>
                        <div className="col-lg-6 col-md-4 col-sm-12 col-12">
                            <form ref={form} onSubmit={sendEmail}>

                                <div className="row form-margin">
                                    <div className="col-lg-6 mb-4">
                                        <div className="form-group">
                                            <label className="form-label text-white">Name</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="to_name"
                                                placeholder="Name"
                                                value={formData.to_name}
                                                onChange={handleInputChange}
                                            />
                                            {errors.to_name && <small style={{ color: 'red' }}>{errors.to_name}</small>}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-4">
                                        <div className="form-group">
                                            <label className="form-label text-white">Designation</label>
                                            <input className="form-control" type="text" name="to_designation" placeholder="Designation" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-4">
                                        <div className="form-group">
                                            <label className="form-label text-white">Company Name</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="to_company"
                                                placeholder="Company Name"
                                                value={formData.to_company}
                                                onChange={handleInputChange}
                                            />
                                            {errors.to_company && <small style={{ color: 'red' }}>{errors.to_company}</small>}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-4">
                                        <div className="form-group">
                                            <label className="form-label text-white">Contact No</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="contact_no"
                                                placeholder="Contact No"
                                                value={formData.contact_no}
                                                onChange={handleInputChange}
                                            />
                                            {errors.contact_no && <small style={{ color: 'red' }}>{errors.contact_no}</small>}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mb-4">
                                        <div className="form-group">
                                            <label className="form-label text-white">Email ID</label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="reply_to"
                                                placeholder="Email ID"
                                                value={formData.reply_to}
                                                onChange={handleInputChange}
                                            />
                                            {errors.reply_to && <small style={{ color: 'red' }}>{errors.reply_to}</small>}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <button className="btn custom_btn" type="submit" disabled={isLoading}>
                                            {isLoading ? (
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            ) : (
                                                'Submit'
                                            )}
                                        </button>
                                        {successMessage && <p style={{ color: 'white', marginTop: '10px' }}>{successMessage}</p>}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-end text-center">
                <p>2024 © Copyright <a href="https://infisuite.com/">Infisuite Technologies</a> - All right reserved</p>
            </div>
        </div>
    );
}


export default Footer;